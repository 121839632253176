import { createStore } from 'vuex'

type urlList = {
  realAccount: string;
  virtualAccount: string;
  login: string;
  androidMt4: string;
  iosMt4: string;
  pcMt4: string;
}

export default createStore({
  state: {
    windowWidth: 0, // 浏览器屏幕宽度
    urlList: {
      realAccount: "",
      virtualAccount: "",
      login: "",
      androidMt4: "",
      iosMt4: "",
      pcMt4: "",
    },
    footerBanner: "",
    newsDetailAction: 0,
    lang: 'zh'
  },
  getters: {
  },
  mutations: {
    windowWidthChange(state, width: number) {
      state.windowWidth = width
    },
    urlListChange(state, urlList: urlList) {
      state.urlList = urlList
    },
    newsDetailActionChange(state, newsDetailAction: number) {
      state.newsDetailAction = newsDetailAction
    },
    langChange(state, lang) {
      state.lang = lang
    },
    footerBannerChange(state, footerBanner: string) {
      state.footerBanner = footerBanner
    }
  },
  actions: {
  },
  modules: {
  }
})
