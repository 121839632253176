/* eslint-disable */
import req from "@/util/request";

export function addAllNews(data: object): Promise<any> {
    return req.post("/cms/news/add", data)
}

export function uploadAdminFile(data: FormData): Promise<any> {
    return req.post(`/cms/admin/upload`, data)
}

export function getChartConf(): Promise<any> {
    return req.get("/cms/chart/conf")
}

export function saveChartConf(data: object): Promise<any> {
    return req.post("/cms/chart/conf", data)
}

export function getChartList(): Promise<any> {
    return req.get("/api/chart/list")
}

export function getNewsList(data: object): Promise<any> {
    return req.get("/api/news/list", {
        params: data
    })
}

export function getCmsNewsList(data: object): Promise<any> {
    return req.get("/cms/news/list", {
        params: data
    })
}

export function getNewsDetail(id: number): Promise<any> {
    return req.get(`/api/news/detail/${id}`)
}

export function updateNews(data: object): Promise<any> {
    return req.post("/cms/news/update", data)
}

export function deleteNewsById(id: number): Promise<any> {
    return req.post(`/cms/news/del/${id}`)
}

export function adminLogin(data: object): Promise<any> {
    return req.post("/api/admin/login", data)
}

export function saveUrlList(data: object): Promise<any> {
    return req.post("/cms/url/update", data)
}

export function getCmsUrlList(): Promise<any> {
    return req.get("/cms/url/list")
}

export function getUrlList(): Promise<any> {
    return req.get("/api/url/list")
}

export function sendMsg(data: object): Promise<any> {
    return req.post("/api/msg/send", data)
}

export function getMsgList(data: object): Promise<any> {
    return req.get("/cms/msg/list", {
        params: data
    })
}

export function addAnnouncement(data: object): Promise<any> {
    return req.post("/cms/announcement/add", data)
}

export function getAnnouncementList(data: object): Promise<any> {
    return req.get("/cms/announcement/list", {
        params: data
    })
}

export function updateAnnouncement(data: object): Promise<any> {
    return req.post("/cms/announcement/update", data)
}

export function delAnnouncement(data: object): Promise<any> {
    return req.post("/cms/announcement/del", data)
}

export function announcementForUser(): Promise<any> {
    return req.get("/api/announcement")
}

export function getFooterBanner(): Promise<any> {
    return req.get(`/api/footer/banner`)
}

export function getHomeNews(): Promise<any> {
    return req.get("/api/home/news")
}

export function getAnnouncementById(id: number): Promise<any> {
    return req.get(`/cms/announcement/item/${id}`)
}

export function addBannerItem(data: object): Promise<any> {
    return req.post("/cms/banner/add", data)
}

export function getBannerList(lang = "zh"): Promise<any> {
    return req.get("/api/banner/list")
}

export function getBannerItem(id: number): Promise<any> {
    return req.get(`/cms/banner/item/${id}`)
}

export function updateBannerItem(data: object): Promise<any> {
    return req.post("/cms/banner/update", data)
}

export function bannerDel(id: number): Promise<any> {
    return req.post(`/cms/banner/del/${id}`)
}

export function updateFooterBanner(data: FormData): Promise<any> {
    return req.post(`/cms/footer/banner`, data)
}

export function removeFooterBanner(data: {id: 0}): Promise<any> {
    return req.post("/cms/footerBanner/remove", data)
}