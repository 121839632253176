import VueCookies, { VueCookies as vc } from "vue-cookies";

const TOKEN = "AdminToken"

export function getToken() {
    return (VueCookies as unknown as vc).get(TOKEN) || ""
}

export function setToken(token: string, expire = 86400) {
    (VueCookies as unknown as vc).set(TOKEN, token, expire)
}

export function delToken() {
    (VueCookies as unknown as vc).remove(TOKEN)
}

export function setAnnouncementC(value: { id: number, num: number }) {
    (VueCookies as unknown as vc).set("Announcement", JSON.stringify(value))
}

export function getAnnouncementC() {
    return (VueCookies as unknown as vc).get("Announcement") || ""
}

export function rmAnnouncementC() {
    (VueCookies as unknown as vc).remove("Announcement")
}