import axios from "axios"
import { getToken, delToken } from "@/util/cookies";
import router from "@/router/index";

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 60000,
});

service.interceptors.request.use(
    (config) => {
        config.headers = {
            token: getToken()
        };
        return config;
    },
    (error) => {
        console.error(error);
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    // eslint-disable-next-line
    (response) => {
        if (response.headers["content-type"].indexOf("json") === -1) {
            return response;
        }
        const res = response.data;
        if (res.code && res.code === 444) {
            // token失效 返回登录页面
            delToken()
            router.replace({ name: "adminLogin" })
        }
        return response.data;
    },
    (error) => {
        console.error(error);
        return Promise.reject(error);
    }
);

export default service;