import "ant-design-vue/es/drawer/style";
import _Drawer from "ant-design-vue/es/drawer";
import "ant-design-vue/es/dropdown/style";
import _Dropdown from "ant-design-vue/es/dropdown";
import _defineProperty from "I:/webdata/aims/aims_vue/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "ant-design-vue/es/menu/style";
import _Menu from "ant-design-vue/es/menu";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable */


import { Options, Vue } from "vue-class-component";
import { UserOutlined, GlobalOutlined, MenuOutlined, CloseOutlined, RightOutlined } from '@ant-design/icons-vue';
import { useStore } from "vuex";
const MenuItem = _Menu.Item;
const SubMenu = _Menu.SubMenu;
let HeaderNav = class HeaderNav extends Vue {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "menuList", [{
      name: "首页",
      key: "home",
      sessionName: "",
      subMenu: []
    }, {
      name: "关于AIMS荣鹰证券",
      key: "company",
      meta: 'about',
      subMenu: [{
        name: "AIMS荣鹰证券简介",
        key: ""
      }, {
        name: "CEO寄语",
        key: ""
      }, {
        name: "资金安全",
        key: ""
      }, {
        name: "荣誉奖项",
        key: ""
      }, {
        name: "公司动态",
        key: ""
      }, {
        name: "联系我们",
        key: ""
      }]
    }, {
      name: "产品中心",
      key: "product",
      meta: 'product',
      subMenu: [{
        name: "股指",
        key: ""
      }, {
        name: "股票",
        key: ""
      }, {
        name: "美油/布油",
        key: ""
      }, {
        name: "伦敦金/伦敦银",
        key: ""
      }, {
        name: "MT4产品",
        key: ""
      }, {
        name: "黄金期货",
        key: ""
      }, {
        name: "数字货币",
        key: ""
      }]
    }, {
      name: "下载服务中心",
      key: "mt4",
      meta: 'trade',
      subMenu: [{
        name: "MT4下载",
        key: ""
      }, {
        name: "注册流程",
        key: ""
      }, {
        name: "存款流程",
        key: ""
      }, {
        name: "取款流程",
        key: ""
      }, {
        name: "常见问题",
        key: ""
      }]
    }, {
      name: "资讯中心",
      key: "quotationShow",
      meta: 'information',
      subMenu: [{
        name: "行情动态",
        key: ""
      }, {
        name: "财经要闻",
        key: ""
      }, {
        name: "快讯直播",
        key: ""
      }, {
        name: "财经日历",
        key: ""
      }]
    }]);

    _defineProperty(this, "store", useStore());

    _defineProperty(this, "visible", false);

    _defineProperty(this, "mobileLangShow", false);
  }

  goToPage(PageName) {
    if (this.$route.name === PageName) {
      this.$router.go(0);
    } else {
      if (PageName === "home") {
        this.$router.replace({
          name: PageName
        });
        return;
      }

      this.$router.replace({
        name: PageName,
        query: {
          action: 1
        }
      });
    }
  }

  mobileGoToPage(index) {
    this.visible = false;

    if (this.$route.meta.key === this.menuList[index].meta) {
      this.$router.go(0);
    } else {
      this.$router.push({
        name: this.menuList[index].key,
        query: {
          action: 1
        }
      });
    }
  }

  langChange(lang) {
    this.store.commit("langChange", lang);
  }

};
HeaderNav = __decorate([Options({
  components: {
    UserOutlined,
    GlobalOutlined,
    MenuOutlined,
    Dropdown: _Dropdown,
    Menu: _Menu,
    SubMenu,
    MenuItem,
    Drawer: _Drawer,
    CloseOutlined,
    RightOutlined
  }
})], HeaderNav);
export default HeaderNav;