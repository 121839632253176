import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: { key: "home", client: "user" },
    component: () => import("@/views/Home.vue")
  },
  {
    path: "/product",
    name: "product",
    meta: { key: "product", client: "user" },
    component: () => import("@/views/ProductCenter.vue")
  },
  {
    path: "/trade",
    name: "trade",
    meta: { key: "trade", client: "user" },
    component: () => import("@/views/TradeCenter.vue"),
    children: [
      {
        path: "/trade/mt4",
        name: "mt4",
        meta: { key: "trade", client: "user" },
        component: () => import("@/page/trade/MT4.vue")
      },
      {
        path: "/trade/process",
        name: "process",
        meta: { key: "trade", client: "user" },
        component: () => import("@/page/trade/Process.vue")
      },
      {
        path: "/trade/problem",
        name: "problem",
        meta: { key: "trade", client: "user" },
        component: () => import("@/page/trade/Problem.vue")
      }
    ]
  },
  {
    path: "/about",
    name: "about",
    meta: { key: "about", client: "user" },
    component: () => import("@/views/AboutAims.vue"),
    children: [
      {
        path: "/about/company",
        name: "company",
        meta: { key: "about", client: "user" },
        component: () => import("@/page/about/Company.vue")
      },
      {
        path: "/about/security",
        name: "security",
        meta: { key: "about", client: "user" },
        component: () => import("@/page/about/Security.vue")
      },
      {
        path: "/about/dynamics",
        name: "dynamics",
        meta: { key: "about", client: "user" },
        component: () => import("@/page/about/Dynamics.vue")
      },
      {
        path: "/about/partner",
        name: "partner",
        meta: { key: "about", client: "user" },
        component: () => import("@/page/about/Partner.vue")
      },
      {
        path: "/company/news",
        name: "companyNews",
        meta: { key: "about", client: "user" },
        component: () => import("@/page/information/NewsDetail.vue")
      }
    ]
  },
  {
    path: "/information",
    name: "information",
    meta: { key: "information", client: "user" },
    component: () => import("@/views/Information.vue"),
    children: [
      {
        path: "/information/broadcast",
        name: "broadcast",
        meta: { key: "information", client: "user" },
        component: () => import("@/page/information/Broadcast.vue")
      },
      {
        path: "/information/calendar",
        name: "calendar",
        meta: { key: "information", client: "user" },
        component: () => import("@/page/information/Calendar.vue")
      },
      {
        path: "/information/quotation",
        name: "quotationShow",
        meta: { key: "information", client: "user" },
        component: () => import("@/page/information/QuotationShow.vue")
      },
      {
        path: "/information/finance",
        name: "financeShow",
        meta: { key: "information", client: "user" },
        component: () => import("@/page/information/FinanceShow.vue")
      },
      {
        path: "/information/news/detail",
        name: "newsDetail",
        meta: { key: "information", client: "user" },
        component: () => import("@/page/information/NewsDetail.vue")
      }
    ]
  },
  {
    path: "/admin",
    redirect: "/admin/quotation/list",
    name: "admin",
    meta: { key: "admin", client: "admin" },
    component: () => import("@/admin/AdminLayout.vue"),
    children: [
      {
        path: "/admin/quotation/list",
        name: "quotation",
        meta: { key: "quotation", client: "admin" },
        component: () => import("@/admin/page/QuotationList.vue")
      },
      {
        path: "/admin/quotation/item",
        name: "quotationItem",
        meta: { key: "quotation", client: "admin" },
        component: () => import("@/admin/page/QuotationItem.vue")
      },
      {
        path: "/admin/finance/list",
        name: "finance",
        meta: { key: "finance", client: "admin" },
        component: () => import("@/admin/page/FinanceList.vue")
      },
      {
        path: "/admin/finance/item",
        name: "financeItem",
        meta: { key: "finance", client: "admin" },
        component: () => import("@/admin/page/FinanceItem.vue")
      },
      {
        path: "/admin/dynamic/list",
        name: "dynamicManage",
        meta: { key: "dynamicManage", client: "admin" },
        component: () => import("@/admin/page/DynamicManage.vue")
      },
      {
        path: "/admin/dynamic/item",
        name: "dynamicItem",
        meta: { key: "dynamicItem", client: "admin" },
        component: () => import("@/admin/page/DynamicItem.vue")
      },
      {
        path: "/admin/settting/url",
        name: "urlSetting",
        meta: { key: "urlSetting", client: "admin", sub: "setting" },
        component: () => import("@/admin/page/UrlSetting.vue")
      },
      {
        path: "/admin/setting/announcement",
        name: "announcement",
        meta: { key: "announcement", client: "admin", sub: "setting" },
        component: () => import("@/admin/page/Announcement.vue")
      },
      {
        path: "/admin/setting/announcement/item",
        name: "announcementItem",
        meta: { key: "announcement", client: "admin", sub: "setting" },
        component: () => import("@/admin/page/AnnouncementItem.vue")
      },
      {
        path: "/admin/setting/banner/list",
        name: "bannerList",
        meta: { key: "bannerList", client: "admin", sub: "setting" },
        component: () => import("@/admin/page/BannerList.vue")
      },
      {
        path: "/admin/setting/banner/item",
        name: "bannerItem",
        meta: { key: "bannerList", client: "admin", sub: "setting" },
        component: () => import("@/admin/page/BannerItem.vue")
      },
      {
        path: "/admin/msg/list",
        name: "message",
        meta: { key: "message", client: "admin" },
        component: () => import("@/admin/page/MessageList.vue")
      },
      {
        path: "/admin/chart/conf",
        name: "chartConf",
        meta: { key: "chartConf", client: "admin", sub: "setting" },
        component: () => import("@/admin/page/ChartConf.vue")
      },
      {
        path: "/admin/footer/banner",
        name: 'footerBanner',
        meta: { key: "footerBanner", client: "admin", sub: "setting" },
        component: () => import("@/admin/page/FooterBanner.vue")
      }
    ]
  },
  {
    path: "/admin/login",
    name: "adminLogin",
    meta: { key: "adminLogin", client: "admin" },
    component: () => import("@/admin/AdminLogin.vue")
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return window.scrollTo(0,0);
  }
})

export default router
